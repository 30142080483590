import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [{
        path: '/',
        name: 'index',
        meta: {
            title: '首页'
        },
        component: () =>
            import ('../components/index.vue')
    },
    {
        path: '/Guide',
        name: 'Guide',
        meta: {
            title: '展商导览'
        },
        component: () =>
            import ('../views/Guide.vue')
    },
    {
        path: '/exhibition',
        name: 'exhibition',
        meta: {
            title: '展会'
        },
        component: () =>
            import ('../views/exhibition.vue')
    },
    {
        path: '/areaBition',
        name: 'areaBition',
        meta: {
            title: '展区'
        },
        component: () =>
            import ('../views/areaBition.vue')
    },
    {
        path: '/newsphoto',
        name: 'newsphoto',
        meta: {
            title: '云新闻'
        },
        component: () =>
            import ('../views/newsphoto.vue')
    },
    {
        path: '/newsphotoList',
        name: 'newsphotoList',
        meta: {
            title: '云展示'
        },
        component: () =>
            import ('../views/newsphotoList.vue')
    },
    {
        path: '/VideoNews',
        name: 'VideoNews',
        meta: {
            title: '视频新闻'
        },
        component: () =>
            import ('../views/VideoNews.vue')
    },
    {
        path: '/knowledge',
        name: 'knowledge',
        meta: {
            title: '茶文化'
        },
        component: () =>
            import ('../views/knowledge.vue')
    },
    {
        path: '/knowledgeDetails',
        name: 'knowledgeDetails',
        meta: {
            title: '茶知识详情'
        },
        component: () =>
            import ('../views/knowledgeDetails.vue')
    },
    {
        path: '/GraphicDetails',
        name: 'GraphicDetails',
        meta: {
            title: '图文详情'
        },
        component: () =>
            import ('../views/GraphicDetails.vue')
    },
    {
        path: '/exhibitorsDetails',
        name: 'exhibitorsDetails',
        meta: {
            title: '展商详情'
        },
        component: () =>
            import ('../views/exhibitorsDetails.vue')
    },
    {
        path: '/reserveBook',
        name: 'reserveBook',
        meta: {
            title: '展会预订'
        },
        component: () =>
            import ('../views/reserveBook.vue')
    },
    {
        path: '/exhibitorFilter',
        name: 'exhibitorFilter',
        meta: {
            title: '展商筛选'
        },
        component: () =>
            import ('../views/exhibitorFilter.vue')
    },
    {
        path: '/video',
        name: 'video',
        meta: {
            title: '视频详情'
        },
        component: () =>
            import ('../components/video.vue')
    },
    {
        path: '/itinerary',
        name: 'itinerary',
        meta: {
            title: '活动日程'
        },
        component: () =>
            import ('../views/itinerary.vue')
    },
    {
        path: '/brandOver',
        name: 'brandOver',
        meta: {
            title: '茶品牌'
        },
        component: () =>
            import ('../views/brandOver.vue')
    }, {
        path: '/headline',
        name: 'headline',
        meta: {
            title: '云头条详情'
        },
        component: () =>
            import ('../views/headline.vue')
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        if (to.path === '/knowledge') {
            if (to.query.idType == '1') {
                document.title = '茶知识'
            } else if (to.query.idType == '2') {
                document.title = '茶历史'
            } else if (to.query.idType == '3') {
                document.title = '茶成就'
            } else if (to.query.idType == '4') {
                document.title = '国际茶'
            } else if (to.query.idType == '5') {
                document.title = '茶生活'
            }
        } else {
            document.title = to.meta.title
        }
    }

    next()
})

export default router